/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
.echeck__form {
  position: relative;
  z-index: 0;
}
html.echeck--pagebusy .echeck__form {
  cursor: wait;
}
html.echeck--pagebusy .echeck__form::after {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.echeck__page__question {
  margin-bottom: 50px; /* FIXME: standardize.. the room between question groups */
}

.echeck__stack .wh-form__fieldgroup {
  display: block;
}

.echeck__stack .wh-form__label {
  width: 100%;
}

.echeck__page:not(.echeck__page--selected) {
  display: none;
}

@media (min-width: 900px) {
  .main__contentwithsidebar__content .opaquepanel {
    padding-top: 0;
  }
  h1 {
    display: flex;
    align-items: center;
    min-height: 77px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
h1 {
  border-bottom: 1px solid #CCCCCC;
}

/*
@media (max-width: $minwidth-pagecontent-twocolumn - 1)
{
  h1
  {
    padding-bottom: 15px;
    border-bottom: 1px solid #CCCCCC;
  }
}
*/
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] {
  background-color: #FDC200;
  padding: 30px;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__label {
  display: block;
  font: bold 24px/31px "Noto Serif", Georgia, times, serif;
  font-style: italic;
  margin: 0 0 30px;
  max-width: none;
  text-align: center;
  width: 100%;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__label:empty {
  display: none;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__fieldline {
  min-height: 62px;
  margin: 0;
  padding: 0;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__optionlabel {
  padding-left: 15px;
  padding-right: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #BE311B;
  border-radius: 3px;
  color: #ffffff;
  font-size: 21px;
  font-weight: bold;
  line-height: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  cursor: pointer;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__optionlabel::after {
  margin-left: 15px;
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 20px;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__optionlabel:hover {
  background-color: rgba(190, 49, 27, 0.8);
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__optiondata--horizontal {
  height: 100%;
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__optionlabel::after {
  content: "\f105" !important;
  font-size: 27px;
}
@media (max-width: 539px) {
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields {
    display: block !important;
    margin: 0;
  }
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fieldline {
    display: block;
  }
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fieldline + .wh-form__fieldline {
    margin-top: 15px;
  }
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__optionlabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 540px) {
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields {
    display: flex;
    margin: 0;
    max-width: none;
    position: relative;
  }
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__fieldline {
    display: block;
    flex: 1;
    min-height: 62px;
    line-height: 62px;
    margin: 0;
    padding: 0;
  }
  .echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__fields .wh-form__fieldline + .wh-form__fieldline {
    margin-left: 30px;
  }
}
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] input.wh-form__radio + label,
.echeck__page--bachelormaster .wh-form__fieldgroup[data-wh-form-group-for=bachelormaster] .wh-form__label::after {
  display: none;
}
.echeck__page--bachelormaster.echeck__page--selected ~ .wh-form__buttongroup {
  display: none;
}

.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__label {
  font: bold 17px/23px "Noto Serif", Georgia, times, serif;
  font-style: italic;
  margin: 0 0 30px;
  padding: 0;
  max-width: none;
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields {
  border-top: 1px solid #c5c5c5;
  max-width: none;
  width: 100%;
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__fieldline {
  border-bottom: 1px solid #c5c5c5;
  margin: 0;
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__fieldline {
  display: flex;
  align-items: baseline;
}
@media (min-width: 480px) {
  .echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__fieldline::before {
    position: relative;
    top: 2px;
    font-style: normal;
    margin-left: 17px;
    margin-right: 20px;
    content: "\f105";
    color: #BE311B;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 20px;
    display: inline-block;
    text-decoration: none !important;
  }
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__fieldline--hidden {
  display: none;
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__optionlabel {
  display: inline-flex;
  min-height: 48px;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0;
  padding-right: 0;
  color: #BE311B;
  font: bold 16px/23px "Noto Serif", Georgia, times, serif;
  font-style: italic;
  cursor: pointer;
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__optionlabel:hover {
  text-decoration: underline;
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__optionlabel::after {
  display: none !important;
}
@media (max-width: 479px) {
  .echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__optionlabel {
    padding-left: 15px;
  }
}
@media (min-width: 480px) {
  .echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__fields .wh-form__optionlabel {
    padding-left: 0px;
  }
}
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] input.wh-form__radio + label,
.echeck__page--programme .wh-form__fieldgroup[data-wh-form-group-for=programme] .wh-form__label::after {
  display: none;
}
.echeck__page--programme.echeck__page--selected ~ .wh-form__buttongroup {
  display: none;
}

.echeck__page--continuewithpreviousform.echeck__page--selected ~ .wh-form__buttongroup .echeck__prevbutton {
  display: none;
}

.echeck__page--checkyourmail.echeck__page--selected ~ .wh-form__buttongroup {
  display: none;
}

/*div.formquestion
{
  margin-bottom: 10px;
}

div.formquestion label.left
{
  float:left;
  width:200px;
}


input.button
{
  margin: 10px 0 10px 0;
  background-color: #000000;
  background-image: none;
  background-repeat: no-repeat;
  border-style: none;
  border-width: thin;
  color: #FFFFFF;
  font-family: Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  height: 20px;
  width: 200px;
  cursor: pointer;
}


span.errortext
{
  color:red;
  font-weight:bold;
}
*/
.echeckresults__item {
  border-top: 1px solid #CCCCCC;
  padding: 15px 14px;
}

.echeckresults__item:last-child {
  border-bottom: 1px solid #CCCCCC;
}

.echeckresults__item--passed {
  background-color: rgba(46, 151, 59, 0.15);
}

.echeckresults__item--condition {
  background-color: #EEEEEE;
}

.echeckresults__item--failed {
  background-color: rgba(224, 32, 32, 0.1);
}

.echeckresults__item__title {
  font: bold 15px/23px "Open Sans";
}
.echeckresults__item--passed .echeckresults__item__title {
  color: #00BB00;
}
.echeckresults__item--condition .echeckresults__item__title {
  color: #B620E0;
}
.echeckresults__item--failed .echeckresults__item__title {
  color: #BE311B;
}

.echeckresults__item__description {
  font: 15px/23px "Open Sans";
}

.echeckresults__item__help {
  margin-top: 20px;
  color: #B620E0;
  font: 14px/23px "Open Sans";
  font-style: italic;
}
.echeckresults__item__help > * {
  font: 14px/23px "Open Sans";
  font-style: italic;
  margin-bottom: 0;
}