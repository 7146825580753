/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
.wh-form__button {
  cursor: pointer;
  height: 44px;
  white-space: nowrap;
  outline: none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  overflow: visible; /* IE fix */
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 15px;
  background-color: #B93419;
  color: #fff;
  border: 0 none;
  transition: opacity 0.3s;
  position: relative;
  border-radius: 3px;
}