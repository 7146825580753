.wh-form__date[type=date] {
  max-width: 170px;
}

.datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  flex: none;
  padding: 0 10px;
}
.datetime__controls {
  display: none;
}
.datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.datetime__date, .datetime__time {
  border: 0 none;
  padding: 0;
}
.datetime__date .datetime__sep, .datetime__time .datetime__sep {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
}
.datetime__part {
  flex: none;
  width: 60px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.datetime__part::-webkit-outer-spin-button, .datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datetime__day, .datetime__month {
  width: 65px;
}
.datetime__year {
  width: 80px;
  text-align: center;
}
.datetime__msec {
  min-width: 36px;
  width: calc(3ch + 8px);
}