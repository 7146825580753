/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
* {
  box-sizing: border-box;
}

/* Stretch page and push footer to end of viewport or page ******************************/
html {
  /* we cannot use a min-height on flex in IE,
     but we can set a fixed 100vh height here and by making it
     flex have body inherit the height but allow it to overflow (but not shrink) using 1 0 auto;
  */
  display: flex;
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
}

.page__body {
  flex: 1 0 auto;
}

/*****************************************************************************************/
/* FIXME: rather use mixin or class for these element */
.header__identity,
.header__divider,
.header__mainmenu,
.page__body,
.page__footer {
  width: 100%;
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.header__identity,
.header__divider,
.header__mainmenu {
  padding-left: 8px;
  padding-right: 8px;
}

@media (min-width: 800px) {
  .page__body {
    padding-top: 8px;
  }
  /* the actual document being published */
  .page__contentarea {
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .page__contentarea > * {
    padding-left: 8px;
    padding-right: 8px;
  }
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: fixed url("web/img/raster.png") repeat, #f8f8f8 fixed url("web/img/default_radboud_6.jpg") top left/cover;
}

.header__identity {
  display: flex;
  align-items: center;
  height: 111px;
  max-height: 14.065vw;
  background-color: #FFFFFF;
}

.header__divider {
  background: url("web/img/strook-navigatie.png") no-repeat 50% 0;
  height: 12px;
}

.header__identity__logo {
  display: flex;
  align-items: center;
  color: #B62B21;
  font: 29px/31px "Noto Serif";
}

.header__identity__logo img {
  display: block;
  width: 368px;
  height: 82.97px;
  flex: none;
}

@media (max-width: 768px) {
  .header__identity__logo {
    font-size: 3.9vw;
  }
  .header__identity__logo img {
    width: 57vw;
    max-width: 368px;
    height: auto;
  }
}
.header__mainmenu {
  display: flex;
  background-color: #B62B21;
  height: 27px;
}

.header__mainmenu > * {
  display: block;
  color: #FFFFFF;
  font: bold 13px/27px "Open Sans";
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
}

.header__mainmenu > * + * {
  margin-left: 12px;
}

.header__mainmenu .mainmenu__item__selected {
  color: #FFFFFF;
  background-color: #000000;
}

.header__mainmenu > *:focus {
  outline: 1px dotted #FFFFFF;
}

@media (hover: hover) {
  .header__mainmenu > *:hover {
    color: #FFFFFF;
    background-color: #000000;
  }
}
.page__body {
  background-color: rgba(255, 255, 255, 0.6);
}

.crumbpath {
  margin-top: 11px;
  background-color: #FFFFFF;
}

.crumbpath {
  display: flex;
  align-items: center;
  /* MIXIN page__contentarea__stickout */
  margin-left: 8px;
  padding-left: 8px;
  margin-right: 8px;
  padding-right: 0;
  background-color: #FFFFFF;
  height: 30px;
}

.crumbpath__seperator {
  font-size: 11px;
  margin-left: 7px;
  margin-right: 7px;
}

.crumbpath__item {
  color: #222222;
  text-decoration: none;
  font: 13px/23px "Open Sans";
}

@media (hover: hover) {
  .crumbpath span:hover,
  .crumbpath a:hover {
    color: #222222;
    text-decoration: underline;
  }
}
@media (max-width: 799px) {
  .crumbpath {
    display: none;
  }
}
.page__footer {
  background: #F5F5F5 url("web/img/strook-footer.png") no-repeat 50% 0;
  padding-top: 37px;
  margin-top: -7px; /* slightly let our fluff overlay the .page__contentarea */
  font: 15px/23px "Open Sans";
  color: #222222;
}

.footer__columns {
  display: flex;
}

.page__footer a {
  color: inherit;
  text-decoration: none;
}

.page__footer a:hover {
  text-decoration: underline;
}

.page__footer__award {
  margin-left: -4px; /* compensate for the dropshadow to the left, we want exact alignement with content columns above */
}

.page__footer__bottombar {
  font: 10px/14px "Open Sans";
  color: #222222;
}

.page__footer__bottombar a {
  display: inline-block; /* prevent breaking within, but allow it when we don't fit on a single line */
}

.footer__column--link {
  color: #666665;
}

@media (min-width: 901px) {
  .page__footer__content {
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .footer__columns {
    justify-content: space-between;
  }
  .footer__column--link {
    margin-top: 30px;
    margin-right: 32px;
  }
  .page__footer__bottombar {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 900px) {
  .page__footer__content {
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .footer__columns,
  .page__footer__bottombar {
    padding-left: 16px;
    padding-right: 16px;
  }
  .footer__columns {
    flex-wrap: wrap;
    font: 13px/18px "Open Sans";
  }
  .footer__column--contactinfo {
    order: 0;
    flex: 1 0 0px;
  }
  .footer__column--award {
    order: 1;
    flex: none;
  }
  .page__footer__award {
    width: 94px;
    height: 94px;
  }
  .footer__column--link {
    margin-top: 10px;
    order: 2;
    width: 100%;
  }
  .page__footer__bottombar {
    margin-top: 15px;
    font: 10px/14px "Open Sans";
  }
}
@media (max-width: 899px) {
  .main__contentwithsidebar__sidebar {
    display: none;
  }
}
@media (min-width: 900px) {
  .main__contentwithsidebar {
    display: flex;
    /* MIXIN page__contentarea__stickout */
    margin-left: 8px;
    padding-left: 0;
    /*padding-left: $padding-side-half;*/
    margin-right: 8px;
    padding-right: 8px;
  }
  .main__contentwithsidebar__sidebar {
    flex: 0 0 232px;
    margin-right: 8px;
  }
  .sectionlabelblock {
    display: flex;
    align-items: center;
    min-height: 77px;
    /*padding-left: $padding-side-half;*/
    background-color: #B62B21;
    color: #FFFFFF;
    font: 23px/31px "Noto Serif";
    font-style: italic;
    padding: 20px 20px 20px 20px;
  }
  .main__contentwithsidebar__content {
    flex: 1 0 0px;
    min-width: 0;
  }
}
.opaquepanel {
  display: inline-block;
  width: 100%;
  background-color: #FFFFFF;
  padding: 25px 15px;
}

.opaquepanel > h1:first-child {
  margin-top: 0;
}

.opaquepanel > .main__contents--default:first-child {
  margin-top: 15px;
}

.page__contentarea > .opaquepanel {
  margin-left: 8px;
  margin-right: 8px;
}

.page__thumbnail {
  width: 100%;
  height: auto;
}

.sectionlabelblock + .page__thumbnail {
  margin-top: 15px;
}

.page--echeck .sectionlabelblock {
  display: none;
}
.page--echeck .page__thumbnail {
  margin-top: 0;
}

.page--echeck--typeselected .sectionlabelblock {
  display: flex;
}
.page--echeck--typeselected .page__thumbnail {
  display: none;
}

.ctabutton {
  display: inline-block;
  flex: none;
  /* reset for <button> */
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  /* NEVER overflow */
  max-width: 100%;
  /* NEVER let content within overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* reset for <a> */
  text-decoration: none;
  color: #FFFFFF;
  background-color: #B93419;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  height: 59px;
  line-height: 59px;
  padding: 0 25px;
}

.ctabutton:active,
.ctabutton:hover {
  background-color: #BE311B;
}

.ctabutton__container {
  display: flex;
  justify-content: center;
}