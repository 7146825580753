/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 0.7;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.7;
  color: inherit;
}

input:-moz-placeholder {
  opacity: 0.7;
  color: inherit;
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form.wh-styledinput {
  /*
  // Mark: fix om te voorkomen dat lange select options de layout oprekken
  select:not(.wh-rtd__toolbarstyle)
  {
    flex: 1 1 auto;
    width: 0;
  }
  */
}
.wh-form.wh-styledinput h3 {
  font-size: 21px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--nomargin {
  margin-bottom: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form.wh-styledinput .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__optionlabel, .wh-form.wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form.wh-styledinput .wh-form__label:empty,
.wh-form.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 200px;
  max-width: 200px;
  min-width: 200px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 200px);
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label:empty + .wh-form__fields {
  max-width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
@media (max-width: 800px) {
  .wh-form.wh-styledinput .wh-form__fieldgroup {
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none;
    max-width: calc(100vw - 40px);
    min-width: 0;
  }
  .wh-form.wh-styledinput .wh-form__fields {
    width: 100%;
  }
}
.wh-form.wh-styledinput select + .wh-form__subfield > label,
.wh-form.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form.wh-styledinput input, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-form.wh-styledinput textarea,
.wh-form.wh-styledinput .wh-form__imgedit,
.wh-form.wh-styledinput .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__timeinputgroup {
  border: 1px solid #888888;
  min-height: 44px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 15px 11px 15px;
  max-width: 100%;
  color: inherit;
  flex: 1;
  border-radius: 0;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-form.wh-styledinput input:disabled, .wh-form.wh-styledinput input[data-wh-form-disabled], .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-form.wh-styledinput textarea:disabled,
.wh-form.wh-styledinput textarea[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__imgedit:disabled,
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__dateinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__timeinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__timeinputgroup[data-wh-form-disabled] {
  color: #C5C5C5;
  border-color: #C5C5C5;
  background-color: #F3F3F3;
  box-shadow: none;
}
.wh-form.wh-styledinput input:focus, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):focus,
.wh-form.wh-styledinput textarea:focus,
.wh-form.wh-styledinput .wh-form__imgedit:focus,
.wh-form.wh-styledinput .wh-form__dateinputgroup:focus,
.wh-form.wh-styledinput .wh-form__timeinputgroup:focus {
  outline: none;
  box-shadow: 0 0 3px rgb(0, 150, 255);
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 50px;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  color: #888888;
  font-weight: 900;
  border-radius: 50%;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f057";
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(26, 125, 215, 0.6) 16.67%, transparent 16.67%, transparent 50%, rgba(26, 125, 215, 0.6) 50%, rgba(26, 125, 215, 0.6) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}
.wh-form.wh-styledinput .wh-form__imgedit {
  width: 150px;
  height: 200px;
  padding: 0;
  flex: none;
}
.wh-form.wh-styledinput .wh-form__imgedit::after {
  display: flex;
  font-family: "Font Awesome 5 Pro";
  content: "\f382"; /* cloud */
  font-weight: 700;
  font-size: 33px;
  align-items: center;
  justify-content: center;
  background-image: none;
  padding-bottom: 40px;
  color: #4A4A4A;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditdelete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditdelete::before {
  width: 20px;
  height: 20px;
  color: #888888;
  font-weight: 900;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f057";
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditholder::before {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 15px;
  color: #888888;
  bottom: 0;
  left: 0;
  padding: 20px;
  content: "Drop or upload an image";
}
.wh-form.wh-styledinput .wh-form__imgedit--hasimage::after,
.wh-form.wh-styledinput .wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled]::after {
  opacity: 0.2;
}
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled] .wh-form__imgeditholder {
  display: none;
}
.wh-form.wh-styledinput .wh-form__imgeditholder::after {
  z-index: 1;
  content: "\f1ce";
  display: inline-block;
  position: absolute;
  display: none;
  width: 80px;
  top: 50%;
  left: 50%;
  height: 80px;
  font-size: 80px;
  line-height: 80px;
  font-weight: 900;
  font-family: "Font Awesome 5 Pro";
  color: #888888;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit::after,
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}
.wh-form.wh-styledinput textarea {
  min-height: 140px;
}
.wh-form.wh-styledinput .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd__stylescope {
  padding: 5px 8px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border-color: #aaa;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input, .wh-form.wh-styledinput .wh-form__fieldgroup--error select,
.wh-form.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: #D50000;
}
.wh-form.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #D50000;
  padding: 8px 0 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__buttongroup {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border-top: 1px solid #c5c5c5;
  padding: 20px 0;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__prevbutton,
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__nextbutton {
  display: inline-flex;
  align-items: center;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__prevbutton::before {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  content: "\f104";
  font-weight: 300;
  font-size: 20px;
  margin-right: 15px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__nextbutton {
  justify-content: space-between;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__nextbutton::after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  font-weight: 300;
  font-size: 20px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__prevbutton[disabled],
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button.echeck__nextbutton[disabled] {
  border: 1px solid #979797;
  background-color: #FFFFFF;
  color: #979797;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
  min-width: 200px;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.selectlist {
  display: inline-block;
  cursor: default;
  vertical-align: middle;
}
.selectlist--replaced {
  display: none;
}
.selectlist__area {
  height: 100%; /* This area */
  overflow: hidden;
}
.selectlist__control {
  height: 100%;
  position: relative;
}
.selectlist__items {
  /*min-height:100%;*/
}
.selectlist__items--open {
  position: fixed;
  cursor: default;
  overflow: auto;
}
.selectlist__item--disabled:first-child {
  display: none;
}
.selectlist__item {
  padding: 5px 15px 5px 15px;
}
.selectlist__item:hover {
  background: #dddddd;
}
.selectlist__items {
  background: #ffffff;
  border: 1px solid #888888;
  font-size: 16px;
  line-height: 22px;
}