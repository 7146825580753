@charset "UTF-8";
/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  width: 24px;
  max-width: 24px;
  flex: 0 0 24px;
  height: 24px;
  vertical-align: baseline;
  cursor: pointer;
  border: 1px solid #979797;
  background-color: #fff;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  color: #222222;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "​";
  font-size: 18px;
  transition: all 0.3s;
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #BFBFBF;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #979797;
}
.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-styledinput input[type=radio] + label::after {
  position: relative;
  top: 4px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.wh-styledinput input[type=radio]:checked + label {
  border-color: #222222;
}
.wh-styledinput input[type=radio]:checked + label::after {
  background-color: #222222;
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #BFBFBF;
}
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: #222222;
}
.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
}
.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  color: #BFBFBF;
}
.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #D50000;
}