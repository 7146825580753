@charset "UTF-8";
/*

!! Don't insert normal style rules here, only SASS variables and mixins
   because they'll get multiplied because this file is included
   in other stylesheets.

*/
/* keep padding an even number, so we can split the padding in two to have boxes
   protruding slightly outside the content grid, but have their content lining up again
   with the content by giving it the other half of the padding */
select {
  /* The SVG image needs preserveAspectRatio="xMaxYMid" to get positioned at the right in IE10/11 (and might also need the viewBox) */
  background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNYXhZTWlkIiB2aWV3Qm94PSIwIDAgNC45NSAxMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==) no-repeat calc(100% - 10px) 50%;
  /*
    background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  */
  /*
  transparent version, however doesn't seem to work:
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS‌​1uYW1lPSJMYXllciAxIi‌​B4bWxucz0iaHR0cDovL3‌​d3dy53My5vcmcvMjAwMC‌​9zdmciIHZpZXdCb3g9Ij‌​AgMCA0Ljk1IDEwIj48ZG‌​Vmcz48c3R5bGU+LmNscy‌​0ye2ZpbGw6IzQ0NDt9PC‌​9zdHlsZT48L2RlZnM+PH‌​RpdGxlPmFycm93czwvdG‌​l0bGU+PHBvbHlnb24gY2‌​xhc3M9ImNscy0yIiBwb2‌​ludHM9IjEuNDEgNC42Ny‌​AyLjQ4IDMuMTggMy41NC‌​A0LjY3IDEuNDEgNC42Ny‌​IvPjxwb2x5Z29uIGNsYX‌​NzPSJjbHMtMiIgcG9pbn‌​RzPSIzLjU0IDUuMzMgMi‌​40OCA2LjgyIDEuNDEgNS‌​4zMyAzLjU0IDUuMzMiLz‌​48L3N2Zz4=) no-repeat 95% 50%;
  */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 24px;
}

@-moz-document url-prefix() {
  select, select:-moz-focusring, select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    /*border:0;*/
  }
}
select[disabled] {
  background-color: #F2F2F2;
  color: #555555;
}