/*!
 * Font Awesome Pro 5.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face
{
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("fa-light-300.woff2") format("woff2"), url("fa-light-300.woff") format("woff"), url("fa-light-300.ttf") format("truetype");
}

.fal
{
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
