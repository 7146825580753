@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");
@import url("../../web/fontawesome5pro/fontawesome.css");
@import url("../../web/fontawesome5pro/light.css");
@import url("../../web/fontawesome5pro/regular.css");
@import url("../../web/fontawesome5pro/solid.css");


html
{
  font: 15px/23px "Open Sans","Trebuchet MS",trebuchet,arial,sans-serif;
  color: #000000;
}

h1
{
  font: bold 24px/31px "Noto Serif",Georgia,times,serif;
  font-style: italic;
  color: #222222;
  margin-bottom: 15px;
}
h2
{
  font: bold 17px/23px "Noto Serif",Georgia,times,serif;
  font-style: italic;
  color: #000000;
}
h3
{
  font: bold 16px/23px "Noto Serif",Georgia,times,serif;
  font-style: italic;
  color: #222222;
}
p
{
  font: 15px/23px "Open Sans";
}

/*
@media (max-width: 768px)
{
  h1
  {
    font: bold 18px/22px "Noto Serif",Georgia,times,serif;
  }
  h2
  {

  }
}
*/


a
{
  color: #B93419;
}

p
{
  margin-bottom: 20px;
  line-height: 160%;
}

p.disclaimer
{
  margin: 30px 0 55px 0;

  padding: 15px;
  background-color: #EEEEEE;
  color: #666665;

  font: 13px/18px "Open Sans";
  font-style: italic;
}
p.disclaimer b
{
  font: bold 14px/23px "Noto Serif";
  font-style: italic;
}


sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0 0 20px;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}
